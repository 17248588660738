import React from 'react';
import { Classable, HasChildren } from '@shapeable/types';
import styled, { css } from 'styled-components';
import { breakpoints, theme } from '@shapeable/theme';
import { WARM_GREY, PageContextDefinition, useAppState, usePageEntity, PageEntityProvider, LayoutShell, SiteFooterLayout, UiConfig, UiProvider } from '@shapeable/ui';
import * as pageLayouts from './page-layouts';
import * as sliceLayouts from './slice-layouts';
import { LANG_STRINGS } from '../lang';
import { LayoutStyles, YELLOW, YELLOW_DARK, themeOverrides } from '../theme';


export type LayoutPropTypes = Classable & HasChildren & {
  path?: string;
  data?: any;
  pageContext?: PageContextDefinition;
};

const LayoutDefaultProps: LayoutPropTypes = {
};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    ${LayoutStyles};
  `,
});

const FooterStyles = breakpoints({
  base: css`
  `,
});

const BodyStyles = breakpoints({
  base: css`
    flex-grow: 1;
  `,
});

// -------- Components -------->

const My = {
  Container: styled(LayoutShell)`${ContainerStyles}`,
  Body: styled.div`${BodyStyles}`,
  Footer: styled(SiteFooterLayout)`${FooterStyles}`,
};

export const Layout: React.FC<LayoutPropTypes> = (props) => {
  const { className, children } = props;

  const [ appState, setAppState, patchAppState ] = useAppState();
  const defaultPageEntity = usePageEntity();

  const { liveMode, pageEntity } = appState;
  

  // const client = useApolloClient();


  const config: UiConfig = {
    layouts: { pageLayouts, sliceLayouts, defaultPageLayout: pageLayouts.PageLayoutDefault },
    langStrings: LANG_STRINGS,
    theme: themeOverrides,
    header: {
      variant: 'overlay',
      hoverColor: YELLOW_DARK,
    },
    menuBar: {
      color: 'light',
      buttonColor: 'light',
      buttonHoverColor: 'dark',
      backgroundColor: YELLOW_DARK,
      // buttonHoverColor: 'primary',
      isInverted: true,
    }
  }

  // const { person } = useActivePerson({ queryExtraFields: 'canAdmin' });

  // React.useEffect(() => {

  //   // const fetchData = async() => {
  //   //   if (liveMode) {
  //   //     const result = await client.query({ query: gql`${PAGE_QUERY}`, variables: { id: defaultPageEntity.id }, fetchPolicy: 'network-only' });
  //   //     return result?.data;
  //   //   }
  //   // };

  //   fetchData().then((result) => {
  //     if (result) {
  //       patchAppState({ pageEntity: result.page });
  //     } else {
  //       patchAppState({ pageEntity: null });
  //     }
  //   });

  // }, [liveMode])

  // console.log("PAGE ENTITY IS", pageEntity);

  return (
    <PageEntityProvider value={pageEntity}>
    {/* <UserMenuProvider value={{ component: person?.canAdmin && UserMenu }}> */}
    <UiProvider value={config}>
      <My.Container
        className={className}
        includeTooltip
        tooltipProps={{
          backgroundColor: '#FFFFFF',
        }}
      >
        <My.Body>
          {children}
        </My.Body>
        <My.Footer backgroundColor={WARM_GREY} />
      </My.Container>
    </UiProvider>
    {/* </UserMenuProvider> */}
    </PageEntityProvider>
  );
  
  
};

Layout.defaultProps = LayoutDefaultProps;

